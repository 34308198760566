import React from "react";
import "./result-output.scss";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DASHBAORD_GEO_FILTER,
  DASHBAORD_KPI_DATA,
  DASHBAORD_ROI_LINE_CHART,
  DASHBAORD_SPEND_CHART_DATA,
  DASHBOARD_CHANNEL_FILTER,
  DASHBOARD_ROI_CHART_DATA,
  DASHBOARD_ROI_TABLE_DATA,
  DASHBOARD_SPEND_LINE_CHART,
  DASHBOARD_SPEND_TABLE_DATA,
  RESULT_GEO_FILTER,
  RESULT_RECOMMENDATION_CHART_DATA,
  RESULT_RECOMMENDATION_TABLE,
  RESULT_ROI_BARCHART,
  RESULT_ROI_TABLE_DATA,
  RESULT_SPEND_BARCHART,
  RESULT_SPEND_TABLE_DATA,
} from "../../../../assets/data/json";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { Services } from "../../../shared/services";
import {
  ISpendChartData,
  ITableApiResponseBody,
  IfilterData,
  ResultChart,
} from "../../../../utils/interfaces/interface";
import {
  BarchartIcon,
  TableIcon,
  BackLinkIcon,
  GenerateIcon,
  DownloadSchemaIcon,
} from "../../../../assets/icons/Icons";
import { BarChart } from "@mui/x-charts";
import CustomTable from "../customTable/custome-table";
import { useTrainer } from "../../services/trainer.service";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { ResultsUnit } from "../../../../utils/enums/enum";


export function ResultOutput() {
  const [loading, setLoading] = React.useState(false);
  const [geoName, setGeoName] = React.useState<string[]>([]);
  const [channelName, setChannelName] = React.useState<string[]>([]);
  const [geoFilterData, setGeoFilterData] = React.useState<IfilterData[]>();
  const [channelFilterData, setChannelFilterData] =
    React.useState<IfilterData[]>();
  const [selectAllGeos, setSelectAllGeos] = React.useState(false);
  const [selectAllChannel, setSelectAllChannel] = React.useState(false);
  const [resultKpi, setResultKpi] = React.useState<any>(null);

  const [spendChartData, setSpendChartData] = React.useState<ISpendChartData | null>();
  const [roiChartData, setRoiChartData] = React.useState<ISpendChartData | null>();
  const [
    marketingChartData,
    setMarketingChartData,
  ] = React.useState<ResultChart | null>();

  const [spendTableData, setSpendTabledata] =
    React.useState<ITableApiResponseBody | null>();
  const [roiTableData, setRoiTableData] =
    React.useState<ITableApiResponseBody | null>();
  const [marketingTableData, setMarketingTableData] = React.useState<ITableApiResponseBody | null>();

  const [showSpendChart, setShowSpendChart] = React.useState(true);
  const [showRoiChart, setShowRoiChart] = React.useState(true);
  const [showMarketingChart, setShowMarketingChart] = React.useState(true);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const navigate = useNavigate();
  const trainer = useTrainer();

  const getGeoChannelFilterData = async () => {
    setLoading(true);
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_FILTERS.toString() + `/${trainer.datasetName}`,
      payload: {
        experiment_id: trainer.experimentDetails.experimentId,
        dataset_id: trainer.datasetName
      },
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {},
      successCb: (res: any) => {
        // key === 'geos' ? setGeoFilterData(res.data) : setChannelFilterData(res.data)
        const geoData: IfilterData[] = [
          {
            label: "",
            key: "geo",
            options: [
              {
                title: "All Geos",
                value: "All Geos",
              },
              ...res.data.geos.map((geo: any) => {
              return {
                title: geo,
                value: geo
              }
            })]
          }
        ]
        const channelData: IfilterData[] = [
          {
            label: "",
            key: "channel",
            options: [
              {
                title: "All Channels",
                value: "All Channels",
              },
              ...res.data.channels.map((channel: any) => {
              return {
                title: channel,
                value: channel
              }
            })]
          }
        ]
        // console.log(geoData, channelData)
        setGeoFilterData(geoData)
        setChannelFilterData(channelData)
        
        const allTitlesGeos = geoData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
            // acc.push(...filter.options.filter((option: any) => option.title != 'All Geos').map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);
  
        // console.log(allTitlesGeos)
        setGeoName(allTitlesGeos);
        setSelectAllGeos(true);       
      
        const allTitlesChannels = channelData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);
  
        // console.log(allTitlesChannels)
        setChannelName(allTitlesChannels);
        setSelectAllChannel(true);
        
        setLoading(false);
      },
      failedCb: () => {
        setLoading(false);
      },
    });
  };

  const getSpendChartData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_SPEND_CHART.toString(),
      payload:{
        dataset_id: trainer?.datasetName,
        process_id: trainer?.experimentDetails.process_id,
        experiment_id: trainer?.experimentDetails.experimentId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_SPEND_BARCHART,
      successCb: (res: any) => {
        //console.log(res.data);
        setSpendChartData(res.data);
      },
      failedCb: () => {},
    });
  };
  

  const getRoiChartData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url:ApiEndPoints.RESULT_ROI_CHART.toString(),
      payload:{
        dataset_id: trainer?.datasetName,
        process_id: trainer?.experimentDetails.process_id,
        experiment_id: trainer?.experimentDetails.experimentId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_ROI_BARCHART,
      successCb: (res: any) => {
        //console.log(res.data);
        setRoiChartData(res.data);
      },
      failedCb: () => {},
    });
  };

  const getMarketingCharttData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_MARKETING_CHART.toString(),
      payload:{
       dataset_id: trainer?.datasetName,
        process_id: trainer?.experimentDetails.process_id,
        experiment_id: trainer?.experimentDetails.experimentId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_RECOMMENDATION_CHART_DATA,
      successCb: (res: any) => {
        // console.log(res.data);
        setMarketingChartData(res.data);
      },
      failedCb: () => {},
    });
  };

  const getSpendTableData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_SPEND_TABLE.toString(),
      payload:{
        dataset_id: trainer?.datasetName,
        process_id: trainer?.experimentDetails.process_id,
        experiment_id: trainer?.experimentDetails.experimentId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_SPEND_TABLE_DATA,
      successCb: (res: any) => {
        setSpendTabledata(res);
      },
      failedCb: (res: any) => {},
    });
  };

  const getRoiTableData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_ROI_TABLE.toString(),
      payload:{
        dataset_id: trainer?.datasetName,
        process_id: trainer?.experimentDetails.process_id,
        experiment_id: trainer?.experimentDetails.experimentId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_ROI_TABLE_DATA,
      successCb: (res: any) => {
        setRoiTableData(res);
      },
      failedCb: (res: any) => {},
    });
  };

  const getMarketingTableData = async () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_MARKETING_TABLE.toString(),
      payload:{
        dataset_id: trainer?.datasetName,
        process_id: trainer?.experimentDetails.process_id,
        experiment_id: trainer?.experimentDetails.experimentId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: RESULT_RECOMMENDATION_TABLE,
      successCb: (res: any) => {
        setMarketingTableData(res);
      },
      failedCb: (res: any) => {},
    });
  };

  const getResultsKPIs = () => {
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.RESULT_KPI_RESULTS.toString() + `/${trainer?.datasetName}`,
      payload:{
        dataset_id: trainer?.datasetName,
        process_id: trainer?.experimentDetails.process_id,
        experiment_id: trainer?.experimentDetails.experimentId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {},
      successCb: (res: any) => {
      //  console.log(res)
        // setMarketingTableData(res);
        setResultKpi(res)
      },
      failedCb: (res: any) => {},
    });
  }

  // React.useEffect(() => {
  // //  console.log('dataset name: ',trainer.datasetName)
  //   if (trainer.datasetName == '') {
  //     navigate('/load-data')
  //   }
  // },[])

  React.useEffect(() => {
    if(!trainer?.experimentDetails?.experimentId) {
      navigate('/load-data')
    }
  }, []);

  React.useEffect(() => {
    if(trainer.datasetName !== "") {
      getGeoChannelFilterData();
      // getGeoChannelFilterData('channels');
    }
  }, [trainer.datasetName]);

  
  React.useEffect(() => {
  //  console.log(trainer.datasetName ,geoName,channelName )
    if (geoName.length == (geoFilterData && geoFilterData[0].options.length - 1)) {
      setSelectAllGeos(true);
    } 
    if (channelName.length == (channelFilterData && channelFilterData[0].options.length - 1)) {
      setSelectAllChannel(true);
    } 
    if(trainer.datasetName !== "" && trainer.datasetName && geoName.length > 0 && channelName.length > 0 ) {
      setResultKpi(null)
      setSpendChartData(null);
      setRoiChartData(null);
      setMarketingChartData(null);
      setSpendTabledata(null)
      setRoiTableData(null)
      setMarketingTableData(null)
      
      getResultsKPIs()
      getSpendChartData();
      getRoiChartData();
      getMarketingCharttData();
      getSpendTableData();
      getRoiTableData();
      getMarketingTableData();
    }
  }, [geoName, channelName]);

  // console.log(spendChartData);

  const handleGeoChange = (event: SelectChangeEvent<typeof geoName>) => {
    const {
      target: { value },
    } = event;
    if (geoName.includes('All Geos')) {
      if (!value.includes("All Geos")) {
        setGeoName([])
        setSelectAllGeos(false);
      } else {
        let newVal = []
        if (typeof value === "string") {
          newVal = value.split(",").filter(d => d != 'All Geos')
        } else {
          newVal = value.filter(d => d != 'All Geos')
        }
        setGeoName(newVal);
        setSelectAllGeos(false);
      }
    }

    // If "All Geos" is selected, set the flag and all options
    else if ((value.includes("All Geos"))) { // && value.length == 1
      if (geoFilterData && geoFilterData.length) {
        const allTitles = geoFilterData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);
        setGeoName(allTitles);
        setSelectAllGeos(true);
      }
    } 
    // else if (!value.length) {
    //   setGeoName([])
    //   setSelectAllGeos(false);
    // } 
    else {
      // On other selections, set the selected values and update the flag
      setGeoName(typeof value === "string" ? value.split(",") : value);
      setSelectAllGeos(false);
    }
  };
  const handleChannelChange = (
    event: SelectChangeEvent<typeof channelName>
  ) => {
    const {
      target: { value },
    } = event;
    // console.log("inside change", value, event);
    // setCallReloadApi(true)
    if (channelName.includes('All Channels')) {
      if (!value.includes("All Channels")) {
        setChannelName([])
        setSelectAllChannel(false);
      } else {
        let newVal = []
        if (typeof value === "string") {
          newVal = value.split(",").filter(d => d != 'All Channels')
        } else {
          newVal = value.filter(d => d != 'All Channels')
        }
        setChannelName(newVal);
        setSelectAllChannel(false);
      }
    }

    // If "All Channels" is selected, set the flag and all options
    else if (value.includes("All Channels")) {
      if (channelFilterData && channelFilterData.length) {
        const allTitles = channelFilterData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);

        setChannelName(allTitles);
        setSelectAllChannel(true);
      }
    } else {
      // On other selections, set the selected values and update the flag
      setChannelName(typeof value === "string" ? value.split(",") : value);
      setSelectAllChannel(false);
    }
  };

  const handleTableRowAction = (action: string, row: any) => {
    // Handle the emitted event here
    //console.log(`Received event: ${action} for row:`, row);
  };

  const handleDownloadMarketingCalendar = () => {
    try {
      fetch(
        ApiEndPoints.RESULT_DOWNLOAD_MARKETING_CALENDAR.toString() + 
        `/?experiment_id=${trainer.experimentDetails.experimentId}`, 
        {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          dataset_id: trainer?.datasetName,
          process_id: trainer?.experimentDetails.process_id,
          experiment_id: trainer?.experimentDetails.experimentId,
          geos: geoName.filter((geo:string) => geo !== 'All Geos'),
          channels: channelName.filter((channel:string) => channel !== 'All Channels' )
        })
      }).then(async response => {
        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers.get("content-disposition");
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);
        const suggestedFileName = filenameMatch ? filenameMatch[1] : 'marketing-calendar' + ".csv";
    
        // Create a Blob from the response data
        const blob =  response.blob();
    
        // Create a download link
        const url = URL.createObjectURL(await blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", suggestedFileName);
        document.body.appendChild(link);
    
        // Trigger the download
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    
        
      })
    }
    catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  return (
    <>
        <Box className="result-output">
          <div className="top-output">
              <div className="left-output">
                  <ul className="list-unstyled d-flex m-0">
                      <li className="px-3">
                        <a role="button" className="back-link" onClick={() => navigate('/result')}> <BackLinkIcon /> Back</a>
                      </li>
                      <li className="px-3">
                        <span>Scenario</span>
                        <strong>{resultKpi?.scenario ? resultKpi.scenario : '-'}</strong>
                      </li>
                      <li className="px-3">
                        <span>Status</span>
                        <strong>Completed</strong>
                      </li>
                      <li className="px-3">
                        <span>Experiment Name</span>
                        <strong>{resultKpi?.experiment_name ? resultKpi.experiment_name : '-'}</strong>
                      </li>
                  </ul>
              </div>
              <div className="right-output">
                {/* <small><GenerateIcon />Generate more insights</small> */}
                <ul className="list-unstyled">
                    <li>
                      <span>Spend</span>
                      <strong>{ResultsUnit.Unit} {resultKpi?.total_spends ? (Math.round(resultKpi.total_spends * 100)/100).toLocaleString() : '-'}</strong>
                    </li>
                    <li>
                      <span>Impactable Sales</span>
                      <strong>{ResultsUnit.Unit} {resultKpi?.total_incremental_sales ? (Math.round(resultKpi.total_incremental_sales*100)/100).toLocaleString() : '-'}</strong>
                    </li>
                    <li hidden>
                      <span>Net Sales</span>
                      <strong>{ResultsUnit.Unit} {resultKpi?.total_sales ? (Math.round(resultKpi.total_sales*100)/100).toLocaleString() : '-'}</strong>
                    </li>
                </ul>
              </div>
          </div>
          {trainer?.experimentDetails.information && trainer?.experimentDetails.information?.length != 0 && (
            <div className="card p-3 mt-3">
              <div className="row g-3">
                {
                  trainer?.experimentDetails.information.map((info: any) => (
                    <div className="col-4">
                      <div className="box d-flex align-items-center">
                          <p className="mb-0">{info}</p>
                      </div>
                    </div>
                  ))
                }
                {/* <div className="col-4">
                  <div className="box d-flex align-items-center">
                      <p className="mb-0">OOH spends have been increased due to the mROI </p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="box d-flex align-items-center">
                      <p className="mb-0">OTT spends have been reduced because of low ROI</p>
                  </div>
                </div> */}
              </div>
            </div>
          )
          }
          <div className="text-end my-3">
            <FormControl className="custom-input me-3">
              {geoFilterData && geoFilterData.length
                ? geoFilterData.map((filter: IfilterData, i: number) => (
                    <FormControl key={filter.key}>
                      <InputLabel id={`geo-filter-label-${filter.key}`}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        labelId={`geo-filter-label-${filter.key}`}
                        id={`geo-filter-${filter.key}`}
                        multiple
                        value={geoName}
                        onChange={handleGeoChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) =>
                          selected.includes("All Geos")
                            ? "All Geos"
                            : selected.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {filter.options.map((data: any, index: number) => (
                          <MenuItem key={data.title} value={data.title}>
                            <Checkbox
                              checked={geoName.indexOf(data.title) > -1}
                            />
                            <ListItemText primary={data.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))
                : null}
            </FormControl>
            <FormControl className="custom-input">
              {channelFilterData && channelFilterData.length
                ? channelFilterData.map((filter: IfilterData, i: number) => (
                    <FormControl key={filter.key}>
                      <InputLabel id={`channel-filter-label-${filter.key}`}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        labelId={`channel-filter-label-${filter.key}`}
                        id={`channel-filter-${filter.key}`}
                        multiple
                        value={channelName}
                        onChange={handleChannelChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) =>
                          selected.includes("All Channels")
                            ? "All Channels"
                            : selected.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {filter.options.map((data: any, index: number) => (
                          <MenuItem key={data.title} value={data.title}>
                            <Checkbox
                              checked={channelName.indexOf(data.title) > -1}
                            />
                            <ListItemText primary={data.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))
                : null}
            </FormControl>
          </div>
          {geoName.length > 0 && channelName.length > 0 ? (
            <>
              <div className="row g-3 mb-3">
                {spendChartData ? (
                  <div className="col-6">
                    <div className="card p-3 h-100">
                      <h6 className="title">
                        Channel-wise Spends & Inc. Sales
                      </h6>
                      <div className="text-center mb-2 graph-icons">
                        <span className={`me-3 ${showSpendChart ? "active-icon" : ""}`}>
                          <BarchartIcon
                            onClick={() => setShowSpendChart(true)}
                          />
                        </span>
                        <span className={!showSpendChart ? "active-icon" : ""}>
                          <TableIcon onClick={() => setShowSpendChart(false)} />
                        </span>
                      </div>
                      <div className="d-flex mt-2">
                        {showSpendChart ? (
                          <BarChart
                            xAxis={[
                              {
                                scaleType: "band",
                                data: spendChartData.chartData.map(
                                  (data) => data.key
                                ),
                              },
                            ]}
                            yAxis={[
                              {
                                id: "leftAxis",
                                scaleType: "linear",
                                label: spendChartData.metaData[0].scale.y1axis.label,
                                labelStyle: { fontSize: 12 },
                              },
                              {
                                id: "rightAxis",
                                scaleType: "linear",
                                label: spendChartData.metaData[0].scale.y2axis.label,
                              },
                            ]}
                            series={[
                              {
                                label: spendChartData.metaData[0]['legends']['legend1'].label,
                                data: spendChartData.chartData.map(
                                  (key) => key.bar1
                                ),
                                color:
                                  spendChartData.metaData[0].legends.legend1
                                    .color,
                                yAxisId: "leftAxis"
                              },
                              {
                                label: spendChartData.metaData[0]['legends']['legend2'].label,
                                data: spendChartData.chartData.map(
                                  (key) => key.bar2
                                ),
                                color:
                                  spendChartData.metaData[0].legends.legend2
                                    .color,
                                yAxisId: "rightAxis"
                              },
                            ]}
                            leftAxis="leftAxis"
                            rightAxis="rightAxis"
                            width={650}
                            height={350}
                            sx={
                              {
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                  // Move the y-axis label with CSS
                                  transform: 'translateX(-35px)',
                                },
                                [`.${axisClasses.right} .${axisClasses.label}`]: {
                                  // Move the y-axis label with CSS
                                  transform: 'translateX(35px)',
                                },
                              }
                          }
                          margin={{
                            left: 80,
                            right: 80
                          }}
                          tooltip={{ trigger: 'axis' }}
                          />
                        ) : (
                          <>
                            {spendTableData ? (
                              <CustomTable
                                data={spendTableData}
                                onTableRowAction={(action:string, row:any) =>
                                  handleTableRowAction(action, row)
                                }
                              />
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row g-3 my-3 page-loader">
                    <CircularProgress />
                  </div>
                )}
                {roiChartData ? (
                  <div className="col-6">
                    <div className="card p-3 h-100">
                      <h6 className="title">Channel-wise ROI & mROI</h6>
                      <div className="text-center mb-2 graph-icons">
                        <span className={`me-3 ${showRoiChart ? "active-icon" : ""}`}>
                          <BarchartIcon onClick={() => setShowRoiChart(true)} />
                        </span>
                        <span className={!showRoiChart ? "active-icon" : ""}>
                          <TableIcon onClick={() => setShowRoiChart(false)} />
                        </span>
                      </div>
                      <div className="d-flex mt-2">
                        {showRoiChart ? (
                          <BarChart
                            xAxis={[
                              {
                                scaleType: "band",
                                data: roiChartData.chartData.map(
                                  (data) => data.key
                                ),
                              },
                            ]}
                            yAxis={[
                              {
                                id: "leftAxis",
                                scaleType: "linear",
                                label: roiChartData.metaData[0].scale.y1axis.label,
                                labelStyle: { fontSize: 12 },
                              },
                              {
                                id: "rightAxis",
                                scaleType: "linear",
                                label: roiChartData.metaData[0].scale.y2axis.label,
                              },
                            ]}
                            series={[
                              {
                                data: roiChartData.chartData.map(
                                  (key) => key.bar1
                                ),
                                color:
                                  roiChartData.metaData[0].legends.legend1
                                    .color,
                                yAxisId: "leftAxis"
                              },
                              {
                                data: roiChartData.chartData.map(
                                  (key) => key.bar2
                                ),
                                color:
                                  roiChartData.metaData[0].legends.legend2
                                    .color,
                                yAxisId: "rightAxis"
                              },
                            ]}
                            leftAxis="leftAxis"
                            rightAxis="rightAxis"
                            width={650}
                            height={350}
                          />
                        ) : (
                          <>
                            {roiTableData ? (
                              <CustomTable
                                data={roiTableData}
                                onTableRowAction={(action:string, row:any) =>
                                  handleTableRowAction(action, row)
                                }
                              />
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="page-loader">
                    <CircularProgress />
                  </div>
                )}
              </div>
              <div className="mb-3">
                {marketingChartData ? (
                  <div className="card p-3">
                    <div className="d-flex justify-content-sm-between">
                    <h6 className="title">
                    Recommended Marketing Calendar for {marketingChartData.metaData.seriesData[0].dataKey} to {marketingChartData.metaData.seriesData.at(-1)?.dataKey}
                      </h6>
                      <Button onClick={() => handleDownloadMarketingCalendar()}><DownloadSchemaIcon className="me-2" /> Download Marketing Calender</Button>
                    </div>
                      <div className="text-center mb-2 graph-icons">
                        <span className={`me-3 ${showMarketingChart ? "active-icon" : ""}`}>
                          <BarchartIcon
                            onClick={() => setShowMarketingChart(true)}
                          />
                        </span>
                        <span className={!showMarketingChart ? "active-icon" : ""}>
                          <TableIcon onClick={() => setShowMarketingChart(false)} />
                        </span>
                      </div>
                      <div className="d-flex mt-2">
                        {showMarketingChart ? (
                          <BarChart
                          dataset={marketingChartData.chartData.map((data:any) => data)}
                          xAxis={[
                            { scaleType: "band", dataKey: "channel" },
                          ]}
                          series={
                            marketingChartData?.metaData?.seriesData
                          }
                          yAxis={[
                            {
                              label: "Spends",
                            },
                          ]}
                          // width={1000}
                          height={300}
                        />
                        ):<>
                        {marketingTableData ? (
                          <CustomTable
                            data={marketingTableData}
                            onTableRowAction={(action:string, row:any) =>
                              handleTableRowAction(action, row)
                            }
                          />
                        ) : null}
                      </>}
                      </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </Box>
    </>
  );
}
import React, { useState } from "react";
import "./dashbaord.scss";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DASHBAORD_GEO_FILTER,
  DASHBAORD_KPI_DATA,
  DASHBAORD_ROI_LINE_CHART,
  DASHBAORD_SPEND_CHART_DATA,
  DASHBOARD_CHANNEL_FILTER,
  DASHBOARD_ROI_CHART_DATA,
  DASHBOARD_ROI_TABLE_DATA,
  DASHBOARD_SPEND_LINE_CHART,
  DASHBOARD_SPEND_TABLE_DATA,
} from "../../../../assets/data/json";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { Services } from "../../../shared/services";
import {
  IDashboardKpi,
  ILineChartData,
  ISpendChartData,
  ITableApiResponseBody,
  IfilterData,
} from "../../../../utils/interfaces/interface";
import {
  BarchartIcon,
  GeoIcon,
  TableIcon,
} from "../../../../assets/icons/Icons";
import { BarChart, LineChart } from "@mui/x-charts";
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import CustomTable from "../customTable/custome-table";
import { useTrainer } from "../../services/trainer.service";
import { ApiEndPoints } from "../../../../utils/enums/apiendpoints";
import { useLocation } from "react-router-dom";

export function DashboardScreen() {
  const [isRendered,setIsRendered] = useState(false)
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [geoName, setGeoName] = React.useState<string[]>([]);
  const [channelName, setChannelName] = React.useState<string[]>([]);
  const [geoFilterData, setGeoFilterData] = React.useState<IfilterData[]>();
  const [channelFilterData, setChannelFilterData] =
    React.useState<IfilterData[]>();
  const [selectAllGeos, setSelectAllGeos] = React.useState(true);
  const [selectAllChannel, setSelectAllChannel] = React.useState(true);

  const [kpiData, setKpiData] = React.useState<IDashboardKpi | null>(null);
  const [spendChartData, setSpendChartData] = React.useState<ISpendChartData | null>(null);
  const [roiChartData, setRoiChartData] = React.useState<ISpendChartData | null>(null);
  const [
    spendLineChartData,
    setSpendLineChartData,
  ] = React.useState<ILineChartData | null>(null);
  const [
    roiLineChartData,
    setRoiLineChartData,
  ] = React.useState<ILineChartData | null>(null);

  const [spendTableData, setSpendTabledata] =
    React.useState<ITableApiResponseBody | null>(null);
  const [roiTableData, setRoiTableData] =
    React.useState<ITableApiResponseBody | null>(null);

  const [showSpendChart, setShowSpendChart] = React.useState(true);
  const [showRoiChart, setShowRoiChart] = React.useState(true);
  const [processSuccess, setProcessSuccess] = React.useState(false);
  const [callReloadApi, setCallReloadApi] = React.useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  const AppService = React.useContext(Services.AppService);
  const auth = useAuth()!;
  const navigate = useNavigate();
  const trainer = useTrainer();

  // React.useEffect(() => {
  // //  console.log('dataset name: ',trainer.datasetName)
  //   if (trainer.datasetName == '') {
  //     navigate('/load-data')
  //   }
  // },[])

  React.useEffect(() => {
    trainer.setProcessId("");
  }, []);


  React.useEffect(() => {
    // console.log('check process')
    if(trainer.processId && isRendered) {
      checkProcess()
    }
    setIsRendered(true)
  }, [trainer.processId]);

  React.useEffect(() => {
    // console.log('get geo channel filter', trainer.datasetName, processSuccess, geoFilterData)
    if(trainer.datasetName !== "" && processSuccess && (!geoFilterData || !channelFilterData)) {
      getGeoChannelFilterData();
    }
  }, [processSuccess]);

  React.useEffect(() => {
    // console.log("337", trainer.datasetName,  geoName, channelName, geoFilterData, channelFilterData )
    

    if (geoName.length == (geoFilterData && geoFilterData[0].options.length - 1)) {
      setSelectAllGeos(true);
      
    } 
    if (channelName.length == (channelFilterData && channelFilterData[0].options.length - 1)) {
      setSelectAllChannel(true);
      
    } 
    if(trainer.datasetName !== "" && trainer.datasetName && geoName.length > 0 && channelName.length > 0 ) {
      setKpiData(null);
      setSpendChartData(null);
      setRoiChartData(null);
      setSpendLineChartData(null);
      setRoiLineChartData(null);
      setSpendTabledata(null)
      setRoiTableData(null)
      if (callReloadApi) {
        checkReloadAPI()
      } else if (!callReloadApi && processSuccess) {
        getKpiData();
        getSpendChartData();
        getRoiChartData();
        getSpendLineCharttData();
        getRoiLineChartData();
        getSpendTableData();
        getRoiTableData();
      }
    }
    
  }, [geoName, channelName, processSuccess]);


  React.useEffect(() => {
  //  console.log(trainer.datasetName)
    if (trainer.datasetName != '') {
      AppService.API_HANDLER({
        auth,
        requestMethod: "post",
        url: ApiEndPoints.SET_DATASET.toString() + trainer.datasetName,
        useGlobalLoader: true,
        showErrorMessage: true,
        showSuccessMessage: false,
        fetchFromApi: true,
        LOCAL_RESPONSE: {
          message: "Selected the data all_test_data_3 from output_data folder'",
        },
        successCb: (res: any) => {
          // trainer.setDatasetName(dataset_name);
          trainer.setProcessId(res.process_id)
          // setTimeout(() => navigate("/dashboard"), 100);
        },
        failedCb: (res: any) => {},
      });
    }
  }, [trainer.datasetName])


  const checkReloadAPI = () => {
    setLoading(true);
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_RELOAD_API.toString() + `/${trainer.datasetName}`,
      payload: {
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' ),
      },
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {},
      successCb: (res: any) => {
        // console.log(res)
        trainer.setProcessId(res.process_id)
        setCallReloadApi(false)
      },
      failedCb: () => {
        setLoading(false);
      },
    });
  }

  const checkProcess = async () => {
    setLoading(true);
    setProcessSuccess(false)
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_PROCESS.toString() + `/${trainer.processId}`,
      payload: {},
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {},
      successCb: (res: any) => {
        if (res.process_status ==  'IN PROGRESS') {
          setTimeout(() => {
            checkProcess()
          }, 1000)
        } else if (res.process_status ==  'FAILED'){
          auth?.setSnackBarDetails({
            message: 'Correct dataset is not loaded.',
            type: "error"
          });
          setLoading(false);
          navigate('/load-data')
        } else {
          setProcessSuccess(true)
        }
      },
      failedCb: () => {
        setLoading(false);
      },
    });
  };
  const getGeoChannelFilterData = async () => {
    setLoading(true);
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_FILTER.toString() + `/${trainer.datasetName}`,
      payload: {},
      useGlobalLoader: true,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: {},
      successCb: (res: any) => {
        // key === 'geos' ? setGeoFilterData(res.data) : setChannelFilterData(res.data)
        const geoData: IfilterData[] = [
          {
            label: "",
            key: "geo",
            options: [
              {
                title: "All Geos",
                value: "All Geos",
              },
              ...res.data.geos.map((geo: any) => {
              return {
                title: geo,
                value: geo
              }
            })]
          }
        ]
        const channelData: IfilterData[] = [
          {
            label: "",
            key: "channel",
            options: [
              {
                title: "All Channels",
                value: "All Channels",
              },
              ...res.data.channels.map((channel: any) => {
              return {
                title: channel,
                value: channel
              }
            })]
          }
        ]
        console.log(geoData, channelData)
        setGeoFilterData(geoData)
        setChannelFilterData(channelData)
        // trainer.setGeoData(geoData)
        // trainer.setChannelData(channelData)
        
        const allTitlesGeos = geoData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
            // acc.push(...filter.options.filter((option: any) => option.title != 'All Geos').map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);
  
        // console.log(allTitlesGeos)
        setGeoName(allTitlesGeos);
        setSelectAllGeos(true);       
      
        const allTitlesChannels = channelData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);
  
        // console.log(allTitlesChannels)
        setChannelName(allTitlesChannels);
        setSelectAllChannel(true);
        
        setLoading(false);
      },
      failedCb: () => {
        setLoading(false);
      },
    });
  };

  const getKpiData = async () => {
    // console.log('getKpiData: ',geoName, channelName, {
    //   dataset_name: trainer.datasetName,
    //   geos: geoName.filter((geo:string) => geo !== 'All Geos'),
    //   channels: channelName.filter((channel:string) => channel !== 'All Channels' )
    // })
    // console.log(trainer.savedDatasets)
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBAORD_KPIS.toString(),
      payload:{
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' ),
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBAORD_KPI_DATA,
      successCb: (res: any) => {
        setKpiData(res);
      },
      failedCb: () => {},
    });
  };

  const getSpendChartData = async () => {
    // console.log('getSpendChartData: ',geoName, channelName, {
    //   dataset_name: trainer.datasetName,
    //   geos: geoName.filter((geo:string) => geo !== 'All Geos'),
    //   channels: channelName.filter((channel:string) => channel !== 'All Channels' )
    // })
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_SPEND_BARCHART.toString(),
      payload:{
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBAORD_SPEND_CHART_DATA,
      successCb: (res: any) => {
        setSpendChartData(res.data);
      },
      failedCb: () => {},
    });
  };
  

  const getRoiChartData = async () => {
    // console.log('getRoiChartData: ',geoName, channelName, {
    //   dataset_name: trainer.datasetName,
    //   geos: geoName.filter((geo:string) => geo !== 'All Geos'),
    //   channels: channelName.filter((channel:string) => channel !== 'All Channels' )
    // })
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url:ApiEndPoints.DASHBOARD_ROI_BARCHART.toString(),
      payload:{
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBOARD_ROI_CHART_DATA,
      successCb: (res: any) => {
        setRoiChartData(res.data);
      },
      failedCb: () => {},
    });
  };

  const getSpendLineCharttData = async () => {
    // console.log('getSpendLineCharttData: ',geoName, channelName, {
    //   dataset_name: trainer.datasetName,
    //   geos: geoName.filter((geo:string) => geo !== 'All Geos'),
    //   channels: channelName.filter((channel:string) => channel !== 'All Channels' )
    // })
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_SPEND_LINECHART.toString(),
      payload:{
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBOARD_SPEND_LINE_CHART,
      successCb: (res: any) => {
        setSpendLineChartData(res.data);
      },
      failedCb: () => {},
    });
  };

  const getRoiLineChartData = async () => {
    // console.log('getRoiLineChartData: ',geoName, channelName, {
    //   dataset_name: trainer.datasetName,
    //   geos: geoName.filter((geo:string) => geo !== 'All Geos'),
    //   channels: channelName.filter((channel:string) => channel !== 'All Channels' )
    // })
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_ROI_LINECHART.toString(),
      payload:{
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBAORD_ROI_LINE_CHART,
      successCb: (res: any) => {
        setRoiLineChartData(res.data);
      },
      failedCb: () => {},
    });
  };

  const getSpendTableData = async () => {
    // console.log('getSpendTableData: ',geoName, channelName, {
    //   dataset_name: trainer.datasetName,
    //   geos: geoName.filter((geo:string) => geo !== 'All Geos'),
    //   channels: channelName.filter((channel:string) => channel !== 'All Channels' )
    // })
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_SPEND_TABLE.toString(),
      payload:{
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBOARD_SPEND_TABLE_DATA,
      successCb: (res: any) => {
        // console.log(res)
        setSpendTabledata(res);
      },
      failedCb: (res: any) => {},
    });
  };

  const getRoiTableData = async () => {
    // console.log('getRoiTableData: ',geoName, channelName, {
    //   dataset_name: trainer.datasetName,
    //   geos: geoName.filter((geo:string) => geo !== 'All Geos'),
    //   channels: channelName.filter((channel:string) => channel !== 'All Channels' )
    // })
    AppService.API_HANDLER({
      auth,
      requestMethod: "post",
      url: ApiEndPoints.DASHBOARD_ROI_TABLE.toString(),
      payload:{
        dataset_name: trainer.savedDatasets[0].options.find((d: any) => d.value == trainer.datasetName).title,
        process_id: trainer.processId,
        geos: geoName.filter((geo:string) => geo !== 'All Geos'),
        channels: channelName.filter((channel:string) => channel !== 'All Channels' )
      },
      useGlobalLoader: false,
      showErrorMessage: true,
      showSuccessMessage: false,
      fetchFromApi: true,
      LOCAL_RESPONSE: DASHBOARD_ROI_TABLE_DATA,
      successCb: (res: any) => {
        setRoiTableData(res);
      },
      failedCb: (res: any) => {},
    });
  };



  const handleGeoChange = (event: SelectChangeEvent<typeof geoName>) => {
    const {
      target: { value },
    } = event;
    // console.log("inside change", value, event);
    setCallReloadApi(true)
    if (geoName.includes('All Geos')) {
      if (!value.includes("All Geos")) {
        setGeoName([])
        setSelectAllGeos(false);
      } else {
        let newVal = []
        if (typeof value === "string") {
          newVal = value.split(",").filter(d => d != 'All Geos')
        } else {
          newVal = value.filter(d => d != 'All Geos')
        }
        setGeoName(newVal);
        setSelectAllGeos(false);
      }
    }

    // If "All Geos" is selected, set the flag and all options
    else if ((value.includes("All Geos"))) { // && value.length == 1
      if (geoFilterData && geoFilterData.length) {
        const allTitles = geoFilterData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);
        setGeoName(allTitles);
        setSelectAllGeos(true);
      }
    } 
    // else if (!value.length) {
    //   setGeoName([])
    //   setSelectAllGeos(false);
    // } 
    else {
      // On other selections, set the selected values and update the flag
      setGeoName(typeof value === "string" ? value.split(",") : value);
      setSelectAllGeos(false);
    }
  };
  const handleChannelChange = (
    event: SelectChangeEvent<typeof channelName>
  ) => {
    const {
      target: { value },
    } = event;

    // console.log("inside change", value, event);
    setCallReloadApi(true)
    if (channelName.includes('All Channels')) {
      if (!value.includes("All Channels")) {
        setChannelName([])
        setSelectAllChannel(false);
      } else {
        let newVal = []
        if (typeof value === "string") {
          newVal = value.split(",").filter(d => d != 'All Channels')
        } else {
          newVal = value.filter(d => d != 'All Channels')
        }
        setChannelName(newVal);
        setSelectAllChannel(false);
      }
    }

    // If "All Channels" is selected, set the flag and all options
    else if (value.includes("All Channels")) {
      if (channelFilterData && channelFilterData.length) {
        const allTitles = channelFilterData.reduce((acc: any, filter: any) => {
          if (filter.options) {
            acc.push(...filter.options.map((option: any) => option.title));
          }
          return acc;
        }, [] as string[]);

        setChannelName(allTitles);
        setSelectAllChannel(true);
      }
    } else {
      // On other selections, set the selected values and update the flag
      setChannelName(typeof value === "string" ? value.split(",") : value);
      setSelectAllChannel(false);
    }
  };


  const handleTableRowAction = (action: string, row: any) => {
    // Handle the emitted event here
    //console.log(`Received event: ${action} for row:`, row);
  };

  return (
    <>
      {trainer.datasetName && trainer.datasetName !== "" ? (
        <Box>
          <div className="text-end">
            <FormControl className="custom-input me-3">
              {geoFilterData && geoFilterData.length
                ? geoFilterData.map((filter: IfilterData, i: number) => (
                    <FormControl key={filter.key}>
                      <InputLabel id={`geo-filter-label-${filter.key}`}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        labelId={`geo-filter-label-${filter.key}`}
                        id={`geo-filter-${filter.key}`}
                        multiple
                        value={geoName}
                        onChange={handleGeoChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) =>
                          selected.includes("All Geos") //selectAllGeos
                            ? "All Geos"
                            : selected.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {/* <MenuItem key={'All Geos'} value={'All Geos'} onClick={() => setSelectAllGeos(previousVal => !previousVal)}>
                          <Checkbox
                            checked={selectAllGeos}
                          />
                          <ListItemText primary={'All Geos'} />
                        </MenuItem> */}
                        {filter.options.map((data: any, index: number) => (
                          // data.title != 'All Geos' &&
                          <MenuItem key={data.title} value={data.title}>
                            <Checkbox
                              checked={geoName.indexOf(data.title) > -1}
                            />
                            <ListItemText primary={data.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))
                : null}
            </FormControl>
            <FormControl className="custom-input">
              {channelFilterData && channelFilterData.length
                ? channelFilterData.map((filter: IfilterData, i: number) => (
                    <FormControl key={filter.key}>
                      <InputLabel id={`channel-filter-label-${filter.key}`}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        labelId={`channel-filter-label-${filter.key}`}
                        id={`channel-filter-${filter.key}`}
                        multiple
                        value={channelName}
                        onChange={handleChannelChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) =>
                          selected.includes("All Channels")
                            ? "All Channels"
                            : selected.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {filter.options.map((data: any, index: number) => (
                          <MenuItem key={data.title} value={data.title}>
                            <Checkbox
                              checked={channelName.indexOf(data.title) > -1}
                            />
                            <ListItemText primary={data.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))
                : null}
            </FormControl>
          </div>
          {geoName.length > 0 && channelName.length > 0 ? (
            <>
              <div className="row card-list g-3 my-3">
                {kpiData && kpiData.data.length > 0 ? (
                  kpiData.data.map((kpi) => (
                    <div className="col">
                      <Card>
                        <CardContent>
                          <small>{kpi.name}</small>
                          <p>
                            <strong>{kpi.value}</strong>
                          </p>
                        </CardContent>
                      </Card>
                    </div>
                  ))
                ) : (
                  <div>
                    <Card>
                      <CardContent>
                        <CircularProgress />
                      </CardContent>
                    </Card>
                  </div>
                )}
              </div>
              <div className="row g-3 mb-3">
                  <div className="col-6">
                    <div className="card p-3 h-100">
                      <h6 className="title">
                        Channel-wise Spends & Impct. Sales
                      </h6>
                      {spendChartData ? (
                        <>
                          <div className="text-center graph-icons">
                            <span className={`me-3 ${showSpendChart ? "active-icon" : ""}`}>
                              <BarchartIcon
                                onClick={() => setShowSpendChart(true)}
                              />
                            </span>
                            <span className={!showSpendChart ? "active-icon" : ""}>
                              <TableIcon onClick={() => setShowSpendChart(false)} />
                            </span>
                          </div>
                          <div className="d-flex mt-2">
                            {showSpendChart ? (
                              <BarChart
                                xAxis={[
                                  {
                                    scaleType: "band",
                                    data: spendChartData.chartData.map(
                                      (data) => data.key
                                    ),
                                  },
                                ]}
                                yAxis={[
                                  {
                                    id: "leftAxis",
                                    scaleType: "linear",
                                    min: spendChartData.metaData[0].scale.y1axis
                                      .minValue,
                                    max: spendChartData.metaData[0].scale.y1axis
                                      .maxValue,
                                    label:spendChartData.metaData[0].scale.y1axis.label,
                                    labelStyle: { fontSize: 12 },
                                  },
                                  {
                                    id: "rightAxis",
                                    scaleType: "linear",
                                    min: spendChartData.metaData[0].scale.y2axis
                                      .minValue,
                                    max: spendChartData.metaData[0].scale.y2axis
                                      .maxValue,
                                    label: spendChartData.metaData[0].scale.y2axis.label,
                                  },
                                ]}
                                series={[
                                  {
                                    label: spendChartData.metaData[0]['legends']['legend1'].label,
                                    data: spendChartData.chartData.map(
                                      (key) => key.bar1
                                    ),
                                    color:
                                      spendChartData.metaData[0].legends.legend1
                                        .color,
                                    yAxisId: "leftAxis"
                                  },
                                  {
                                    label: spendChartData.metaData[0]['legends']['legend2'].label,
                                    data: spendChartData.chartData.map(
                                      (key) => key.bar2
                                    ),
                                    color:
                                      spendChartData.metaData[0].legends.legend2
                                        .color,
                                    yAxisId: "rightAxis"
                                  },
                                ]}
                                leftAxis="leftAxis"
                                rightAxis="rightAxis"
                                width={650}
                                height={350}
                                sx={
                                    {
                                      [`.${axisClasses.left} .${axisClasses.label}`]: {
                                        // Move the y-axis label with CSS
                                        transform: 'translateX(-35px)',
                                      },
                                      [`.${axisClasses.right} .${axisClasses.label}`]: {
                                        // Move the y-axis label with CSS
                                        transform: 'translateX(35px)',
                                      },
                                    }
                                }
                                margin={{
                                  left: 80,
                                  right: 80
                                }}
                                tooltip={{ trigger: 'axis' }}
                              />
                            ) : (
                              <>
                                {spendTableData ? (
                                  <CustomTable
                                    data={spendTableData}
                                    onTableRowAction={(action:string, row:any) =>
                                      handleTableRowAction(action, row)
                                    }
                                  />
                                ) : null}
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="g-3 my-3 page-loader">
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card p-3 h-100">
                      <h6 className="title">Channel-wise ROI & mROI</h6>
                      {roiChartData ? (
                        <>
                          <div className="text-center graph-icons">
                            <span className={`me-3 ${showRoiChart ? "active-icon" : ""}`}>
                              <BarchartIcon onClick={() => setShowRoiChart(true)} />
                            </span>
                            <span className={!showRoiChart ? "active-icon" : ""}>
                              <TableIcon onClick={() => setShowRoiChart(false)} />
                            </span>
                          </div>
                          <div className="d-flex mt-2">
                            {showRoiChart ? (
                              <BarChart
                                xAxis={[
                                  {
                                    scaleType: "band",
                                    data: roiChartData.chartData.map(
                                      (data) => data.key
                                    ),
                                  },
                                ]}
                                yAxis={[
                                  {
                                    id: "leftAxis",
                                    scaleType: "linear",
                                    min: roiChartData.metaData[0].scale.y1axis
                                      .minValue,
                                    max: roiChartData.metaData[0].scale.y1axis
                                      .maxValue,
                                    label: roiChartData.metaData[0].scale.y1axis.label,
                                    labelStyle: { fontSize: 12 },
                                  },
                                  {
                                    id: "rightAxis",
                                    scaleType: "linear",
                                    min: roiChartData.metaData[0].scale.y2axis
                                      .minValue,
                                    max: roiChartData.metaData[0].scale.y2axis
                                      .maxValue,
                                    label: roiChartData.metaData[0].scale.y2axis.label,
                                  },
                                ]}
                                series={[
                                  {
                                    label: roiChartData.metaData[0].scale.y1axis.label,
                                    data: roiChartData.chartData.map(
                                      (key) => key.bar1
                                    ),
                                    color:
                                      roiChartData.metaData[0].legends.legend1
                                        .color,
                                    yAxisId: "leftAxis"
                                  },
                                  {
                                    label: roiChartData.metaData[0].scale.y2axis.label,
                                    data: roiChartData.chartData.map(
                                      (key) => key.bar2
                                    ),
                                    color:
                                      roiChartData.metaData[0].legends.legend2
                                        .color,
                                    yAxisId: "rightAxis"
                                  },
                                ]}
                                leftAxis="leftAxis"
                                rightAxis="rightAxis"
                                width={650}
                                height={350}
                                // sx={
                                //      {
                                //         [`.${axisClasses.left} .${axisClasses.label}`]: {
                                //           // Move the y-axis label with CSS
                                //           transform: 'translateX(-35px)',
                                //         },
                                //       }
                                // }
                              />
                            ) : (
                              <>
                                {roiTableData ? (
                                  <CustomTable
                                    data={roiTableData}
                                    onTableRowAction={(action:string, row:any) =>
                                      handleTableRowAction(action, row)
                                    }
                                  />
                                ) : null}
                              </>
                            )}
                          </div>
                      </>
                    ) : (
                      <div className="page-loader">
                        <CircularProgress />
                      </div>
                    )}
                    </div>
                  </div>
              </div>
              <div className="row g-3 mb-4">
                  <div className="col-6">
                    <div className="card p-3 h-100">
                      <h6 className="title">
                        Response Curve for selected channel/(s)
                      </h6>
                      {spendLineChartData ? (
                        <div className="d-flex mt-2">
                          <LineChart
                            xAxis={[
                              {
                                id: "xaxis",
                                data: spendLineChartData.chartData.map(
                                  (data: any) => data.key
                                ),
                                label:
                                  spendLineChartData.metaData[0].scale.xaxis
                                    .label,
                              },
                            ]}
                            yAxis={[
                              {
                                id: "axis",
                                label:
                                  spendLineChartData.metaData[0].scale.yaxis
                                    .label,
                              },
                            ]}
                            series={[
                              {
                                label:
                                  spendLineChartData.metaData[0].scale.yaxis
                                    .label,
                                data: spendLineChartData.chartData.map(
                                  (data: any) => data.value
                                ),
                                color: "#2A97C3",
                                showMark: false,
                              },
                            ]}
                            width={650}
                            height={350}
                            sx={
                              {
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                  // Move the y-axis label with CSS
                                  transform: 'translateX(-35px)',
                                },
                                [`.${axisClasses.right} .${axisClasses.label}`]: {
                                  // Move the y-axis label with CSS
                                  transform: 'translateX(35px)',
                                },
                              }
                          }
                          margin={{
                            left: 80,
                            right: 80
                          }}
                          />
                        </div>
                      ) : (
                        <div className="page-loader">
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="card p-3 h-100">
                      <h6 className="title">mROI for selected channel/(s)</h6>
                      {roiLineChartData ? (
                        <div className="d-flex mt-2">
                          <LineChart
                            xAxis={[
                              {
                                data: roiLineChartData.chartData.map(
                                  (data: any) => data.key
                                ),
                                label:
                                  roiLineChartData.metaData[0].scale.xaxis.label,
                              },
                            ]}
                            yAxis={[
                              {
                                label:
                                  roiLineChartData.metaData[0].scale.yaxis.label,
                              },
                            ]}
                            series={[
                              {
                                label:
                                roiLineChartData.metaData[0].scale.yaxis.label,
                                data: roiLineChartData.chartData.map(
                                  (data: any) => data.value
                                ),
                                color: "#2A97C3",
                                showMark: false,
                              },
                            ]}
                            width={650}
                            height={350}
                          />
                        </div>
                      ) : (
                        <div className="page-loader">
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
              </div>
            </>
          ) : (
            <div className="page-loader">
              <CircularProgress />
            </div>
          )}
        </Box>
      ) : (
        // <div>Please Select Dataset</div>
        <div className="page-loader">
        <CircularProgress />
      </div>
      )}
    </>
  );
}

function setLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box/Box";
import { useNavigate } from "react-router";
import { TrainerProvider, useTrainer } from "../../services/trainer.service";
import { Services } from "../../../shared/services";
import { useAuth } from "../../../../utils/auth/auth.guard";
import { LoadDataLanding, DashboardLanding, OptimizeLanding, ResultsLanding, TileInactive, NextArrowLanding } from "../../../../assets/icons/Icons";
import { LANDING_DASHBOARD_DATA } from "../../../../assets/data/json";
import { ILandingDataConfig, IStepWiseDataConfig } from "../../../../utils/interfaces/interface";
import './landing-dashboard.scss'

export default function LandingPage() {
    const navigate = useNavigate();
    const AppService = React.useContext(Services.AppService);
    const auth = useAuth()!;
    const trainer = useTrainer()!;

    const [landingData, setLandingData] = useState<ILandingDataConfig[]>([]);

    useEffect(() => {
        // console.log(trainer.stepWiseData)
        AppService.API_HANDLER({
            auth,
            requestMethod: "get",
            url: "",
            useGlobalLoader: true,
            showErrorMessage: true,
            showSuccessMessage: false,
            fetchFromApi: false,
            LOCAL_RESPONSE: LANDING_DASHBOARD_DATA,
            successCb: (res: ILandingDataConfig[]) => {
                setLandingData(res)
            },
            failedCb: () => {
            }
        });
    },[])

    const handleDisplaySvgIcon = (tileName: string) => {
        switch (tileName) {
            case 'load':
                return <LoadDataLanding />
            case 'dashboard':
                return <DashboardLanding />
            case 'optimize':
                return <OptimizeLanding />
            case 'results':
                return <ResultsLanding />
            default:
                return <ResultsLanding />
        }
    }

    return (
        <TrainerProvider>
            <Box className="landing-dashboard-container d-flex flex-column h-100">
                <Box className='welcome-container'>
                    <div className="welcome-text-container">
                        <p className="welcome-text">
                            <span className="welcome-underline">Welcome to the</span> <span className="welcome-mix">Mix</span>Harbour...
                        </p>
                        <p className="welcome-subtext">
                            A MARKETING MIX BUDGET OPTIMIZER!
                        </p>
                        <p className="welcome-description">
                        Welcome! Upload your spend, sales, event data, etc., to get detailed analytics, compute ROIs and MROIs, and optimize your budget for scenarios like Fixed Spend, Target Attainment, and Maximum Attainment.                        
                        </p>
                    </div>
                </Box>
                <Box className="tiles-container">
                    {landingData.map((tile: ILandingDataConfig, index: number) => (
                        <>
                            <Box className={`tiles ${trainer.stepWiseData[tile.slug].active ? 'active' : ''}`} onClick={() => navigate(`${tile.url}`)}>
                                {!trainer.stepWiseData[tile.slug].active && 
                                    <div className="tile-inactive-icon">
                                        <TileInactive />
                                    </div>
                                }                            
                                <div className="tile-image">
                                    {handleDisplaySvgIcon(tile.slug)}
                                </div>
                                <p className="tile-title">
                                    {tile.title}
                                </p>
                                <p className="tile-desc">
                                    {tile.desc}
                                </p>
                                

                            </Box>
                            {index < landingData.length - 1 &&
                                <NextArrowLanding />
                            }
                        </>
                    ))}
                </Box>

            </Box> 
        </TrainerProvider>

    )
}